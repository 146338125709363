import { slicesMap as PageBody } from './PageBody'
import { slicesMap as ProjectBody } from './ProjectBody'
import { slicesMap as ClosureBody } from './ClosureBody'

// Export all slice zone components as a flat map.
export const slicesMap = {
  ...PageBody,
  ...ProjectBody,
  ...ClosureBody,
}
